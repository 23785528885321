import styled from "styled-components";
import { CloseSquare } from '@styled-icons/evaicons-solid';
import {User} from '@styled-icons/boxicons-regular';
import {AddCircle}  from '@styled-icons/ionicons-sharp';
import {Earth} from '@styled-icons/ionicons-outline';
import {PanelBottom} from '@styled-icons/fluentui-system-filled';

export const Principal = styled(Earth)`
max-width: 30px;
max-height: 30px;
margin: 5px;
color: white;
`;

export const IconPanel = styled(PanelBottom)`
max-width: 30px;
max-height: 30px;
margin: 5px;
color: white;
`;

export const ButtonLogout = styled(CloseSquare)`
max-width: 30px;
max-height: 30px;
margin: 5px;
color: white;
`;

export const ButtonUser = styled(User)`
max-width: 30px;
max-height: 30px;
margin: 5px;
color: white;
`;

export const AddButton = styled(AddCircle)`
width: 30px;
color: white;
height: 30px;
margin: 5px;
`;

export const HeaderContainer = styled.div`
display: flex;
justify-content: flex-end;
background-image: url("https://api.uniscestudantil.org.br/images/img_003.jpeg");
height: 50px;

.logoHeader {
  position: absolute;
  left: 15px;
  background-color: #fff;
  max-width: 160px;
  align-self: flex-start;
  } 

  .ButtonHeader {
    margin-top: 2px;
    margin-right: 10px;
    border: none;
    height: 45px;
    color: white;
    background: rgba(76, 175, 80, 0);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 110px;
  }`;
