import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from "./modal";
import { HeaderContainer, ButtonLogout, AddButton, ButtonUser, Principal, IconPanel } from "../styled/header";
import {jwtDecode} from "jwt-decode";
const { apiUrl} = require('../services/requests');

const Header = (props) => {

  const { location } = props;
  const [modalVisivel, setModalVisivel] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: { id, role } } = jwtDecode(user);

  const navigate = useNavigate();
  const painelUsuario = () => navigate(`/user/${id}`);

  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setModalVisivel(prev => !prev)
  }

  const Loggout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  }

  const site = () => navigate('/');

  const painel = () => navigate('/painel');

  const gestores = () => navigate('/gestores');

  return (
    <>
      <HeaderContainer>
        <img className="logoHeader" src={`${apiUrl}/images/000065-01-l.png`} alt="logo" />
        <button
          className="ButtonHeader"
          onClick={() => site()}>
          <Principal />
          Site</button>
        {role === 'administrador' && location !== 'gestores' ?
          <button
            className="ButtonHeader"
            onClick={() => gestores()}>
            <Principal />
            Gestores
          </button> : null}
        {location === 'gestores' ?
          <button
            className="ButtonHeader"
            onClick={painel}>
            <IconPanel />
            Vendas
          </button> :
          <div className="containerModal">
            <button className="ButtonHeader"
              onClick={openModal}>
              <AddButton />
              Nova Carteira
            </button>
            <Modal modalVisivel={modalVisivel} setModalVisivel={setModalVisivel} />
          </div>}
        <button className="ButtonHeader"
          type='submit'
          onClick={() => painelUsuario()}>
          <ButtonUser />
          Usuario
        </button>
        <button className="ButtonHeader"
          type="submit"
          onClick={() => Loggout()}>
          <ButtonLogout />
          Logout  </button>
      </HeaderContainer>
    </>
  )
}

export default Header;