import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TabelaCarteirasAdm from './TabelaCarteirasAdm';
import moment from 'moment-timezone';
import TabelaCarteirasAdmFiltro from "./TabelasCarteirasAdmFiltro";
import TabelaCarteiras from "./TabelaCarteiras";
import { requestData } from "../services/requests";
import {jwtDecode} from 'jwt-decode';
import TabelaCarteirasFiltro from "./TabelaCarteirasFiltro";
import { funcaoOrganizar } from "../services/funcoesAuxiliares";

const Carteiras = ({ error, filtro }) => {
  const [carteirasBd, setCarteiras] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const { data: { role, id } } = jwtDecode(user);
  const [ bloqueio, setBloqueio] = useState(null);
  const merged = funcaoOrganizar(carteirasBd);
  const navigate = useNavigate();
 
  const hoje = moment().format().split('T')[0];
  const mesPassado = moment().subtract(1, 'month').format();
  const MesAnterior = mesPassado.split('T')[0];

  const getCarteiras = useCallback(async () => {
    const carteirasGestor = await requestData(`/carteiras/?id=${id}&dataInicial=${MesAnterior}&dataFinal=${hoje}`)
    setCarteiras(carteirasGestor);
  }, [id, hoje, MesAnterior]);

  const toggleListVisibility = (e) => {
    const tabela = e.target.querySelector('#tabela');
    
    if(tabela) {
      if (tabela.style.display === 'none') {
        tabela.style.display = 'flex';
      } else {
        tabela.style.display = 'none';
      }
    }
    
  };

  const getUser = useCallback(async () => {
    const user = await requestData(`/user/${id}`);
    setBloqueio(user.bloqueio);
  }, [id]);

  useEffect(() => {
    getCarteiras();
    getUser()
    if (bloqueio === true) {
      localStorage.removeItem('user');
      alert('BLOQUEADO!!!');
      return navigate('/login') ;
    } 
  }, [getCarteiras, navigate, bloqueio, getUser]);
  
    if (role === "administrador") {
      if (error) {
        return (<p> Informação Não Encontrada! </p>)
      } if (filtro.length > 0) {
        const filtros = funcaoOrganizar(filtro);
        return (
          <TabelaCarteirasAdmFiltro filtros={filtros} toggleListVisibility={toggleListVisibility} />
        )
      } else {
        return (
          <TabelaCarteirasAdm merged={merged} toggleListVisibility={toggleListVisibility} />
        )
      }
    }
    if (error) {
      return (<p> Informação Não Encontrada! </p>)
    } if (filtro.length > 0) {
      const filtros = funcaoOrganizar(filtro);
      return (
        <TabelaCarteirasFiltro filtros={filtros} toggleListVisibility={toggleListVisibility} />
      )
    } else {
      return (
        <TabelaCarteiras merged={merged} toggleListVisibility={toggleListVisibility} />
      )
    }  
}

export default Carteiras;
