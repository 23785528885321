import React, { useState, useEffect } from "react";
import {
  FormContainer,
  LabelContainer,
  InputContainer,
  ButtonCarteira,
  DivCarteira,
  ChecarCpf,
  Confirmar
} from "../styled/CriarCarteira";
import EnviarImagem from "./EnviarImagem";
import {jwtDecode} from 'jwt-decode';
import moment from 'moment-timezone';
import { cpfMask } from '../services/funcoesAuxiliares';
import InputMask from 'react-input-mask';
const { criarCarteiras, uploadImage, apiUrl } = require('../services/requests');

const CriarCarteira = () => {
  const date = new Date();
  const newDate = moment(date).locale('pt-br').format('YYYY-MM-DD HH:mm:ss');
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  const user = JSON.parse(localStorage.getItem('user'));
  const { data: { id } } = jwtDecode(user);

  const AnoMes = moment().format('YYMM');
  var random2 = '', d = 0; while (d < 3) { random2 += Math.floor(Math.random() * 10); d++ }
  var random1 = '', c = 0; while (c < 3) { random1 += Math.floor(Math.random() * 10); c++ }
  const codUso = `${AnoMes}-${random1}.${random2}`;

  const [nome, setNome] = useState('');
  const [instituicaoEnsino, setInstituicaoEnsino] = useState('');
  const [curso, setCurso] = useState('');
  const [imagemInicial] = useState(`${apiUrl}/images/0.png`);
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [imagem, setImagem] = useState('');
  const [dataEmissao] = useState(newDate);
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const criarCarteira = async (event) => {
    event.preventDefault();

    if (isButtonDisabled || isSubmitting) return;
    setIsSubmitting(true);

    try {
      const endpoint = '/carteiras';
      const upload = '/upload-image';

      if (imagem !== "") {
        const formData = new FormData();
        formData.append('image', imagem)

        await uploadImage(upload, formData);
      }

      await criarCarteiras(endpoint, {
        foto: imagem === "" ? imagemInicial : `${apiUrl}/images/${imagem.name}`,
        codigoUso: codUso,
        nome,
        instituicaoEnsino,
        curso,
        cpf,
        rg,
        dataNascimento,
        dataValidade,
        dataEmissao,
        user_id: id,
        email,
        telefone,
        observacao: ""
      });
      setError(false);
      alert("Carteira Criada Com Sucesso!");
      setNome('');
      setInstituicaoEnsino('');
      setCurso('');
      setCpf('');
      setRg('');
      setDataNascimento('');
      setEmail('');
      setTelefone('');
    } catch (error) {
      if (error.request.status === 409) {
        setError(true);
      }
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
  
    const formeData = [
      nome,
      instituicaoEnsino,
      curso,
      cpf,
      rg,
      dataNascimento
    ]

    const isValidFormData = formeData.every((value) => value !== "");
    setIsButtonDisabled(!isValidFormData);
  }, [nome, instituicaoEnsino, curso, cpf, rg, dataNascimento]);

  return (
    <DivCarteira>
      <>
        <EnviarImagem img={imagem} setImg={setImagem} />
        <FormContainer>
          <LabelContainer htmlFor="nome"> Nome:
            <p>
              <InputContainer
                value={nome}
                id="nome"
                type="text"
                onChange={(e) => setNome(e.target.value.toUpperCase())}
              />
            </p>
          </LabelContainer>
          <LabelContainer htmlFor="InstEnsino"> Instituição:
            <p>
              <InputContainer
                id="InstEnsino"
                value={instituicaoEnsino}
                type="text"
                onChange={(e) => setInstituicaoEnsino(e.target.value.toUpperCase())}
              />
            </p>
          </LabelContainer>
          <LabelContainer htmlFor="Curso"> Curso:
            <p>
              <InputContainer
                id="Curso"
                value={curso}
                type="text"
                onChange={(e) => setCurso(e.target.value.toUpperCase())}
              />
            </p>
          </LabelContainer>
          <LabelContainer htmlFor="cpf"> CPF:
            <p>
              <InputContainer
                id="cpf"
                value={cpf}
                type="text"
                onChange={(e) => setCpf(cpfMask(e.target.value))}
              />
            </p>
          </LabelContainer>
          <a href="https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp"
            target="_blank" rel="noreferrer">
            <ChecarCpf />
            Validar Cpf
          </a>
          {(error) ? (<p>
            {
              'CPF existente'
            }
          </p>) : null
          }
          <LabelContainer htmlFor="RG"> RG:
            <p>
              <InputContainer
                id="RG"
                type="text"
                value={rg}
                onChange={(e) => setRg(e.target.value)}
              />
            </p>
          </LabelContainer>
          <LabelContainer htmlFor="DataNascimento"> Nascimento:
            <p>
              <InputMask
                mask="99/99/9999"
                id="DataNascimento"
                type="text"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
              />
            </p>
          </LabelContainer>
          <LabelContainer htmlFor="email"> Email:
            <p>
              <InputContainer
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </p>
          </LabelContainer>
          <LabelContainer htmlFor="telefone"> Telefone:
            <p>
              <InputMask
                mask="(99) 9 9999-9999"
                id="telefone"
                type="text"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </p>
          </LabelContainer>
          <ButtonCarteira
            type="submit"
            disabled={isButtonDisabled || isSubmitting}
            onClick={(event) => criarCarteira(event)}>
            <Confirmar />
            Confirmar
          </ButtonCarteira>
        </FormContainer>
      </>
    </DivCarteira>
  )
}

export default CriarCarteira;