import React, { useEffect, useCallback, useState } from "react";
import DownloadCarteiras from "./downloadCarteira";
import VisualizarCarteirasMobile from "./VisualizarCarteirasMobile";
import { requestData } from "../services/requests";
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { DivGlobal, Footer, Header, Section } from "../styled/NovaPaginaVisualizarCarteiras";
const { criarCarteiras, apiUrl } = require('../services/requests');

const NovaPaginaVisualizarCarteiras = () => {
  const largura = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const url_atual = window.location.href;
  const id = url_atual.split('/')[4];
  const [carteira, setCarteira] = useState({});
  const [gestorResponsavel, setGestor] = useState({});
  const date = new Date();
  const newDate = moment(date).locale('pt-br').format('YYYY-MM-DD HH:mm:ss');
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  const MensagemWhatsAppAjuda = `https://api.whatsapp.com/send?phone=${gestorResponsavel.telefone}&text=Olá,`
    + `Gostaria de informações sobre a Carteira nº ${carteira.codigoUso}`
  const mensagemRenovacao = `https://api.whatsapp.com/send?phone=${gestorResponsavel.telefone}&text=Olá,`
    + `Gostaria de solicitar a renovação da carteira nº ${carteira.codigoUso}`;
  const [componenteRenderizado, setComponenteRenderizado] = useState(false);
  const [renovacaoClicada, setRenovacaoClicada] = useState(false);


  const handleClickDownload = async () => {
    setComponenteRenderizado(true);
  }

  const handleClickRenovar = async (e) => {
    if (!user) {
      window.location.href = mensagemRenovacao;
    }
    setRenovacaoClicada(true);
    handleClick(e);
  }

  const handleClick = async (e) => {
    e.preventDefault();
    if (user) {
      const { data } = jwtDecode(user);
      try {
        const endpoint = '/carteiras/renovacao';
        await criarCarteiras(endpoint, {
          foto: carteira.foto,
          codigoUso: carteira.codigoUso,
          nome: carteira.name,
          instituicaoEnsino: carteira.instituicaoEnsino,
          curso: carteira.curso,
          cpf: carteira.cpf,
          rg: carteira.rg,
          dataNascimento: carteira.dataNascimento,
          dataValidade,
          dataEmissao: newDate,
          user_id: data.id,
          email: carteira.email,
          telefone: carteira.telefone,
        });
        navigate('/painel');
      } catch (error) {
        console.log(error);
      }
    }
  }

  const getCarteira = useCallback(async () => {
    const carteiras = await requestData(`/carteiras/${id}`);
    setCarteira(carteiras);
    setGestor(carteiras.gestorResponsavel);
  }, [id]);

  useEffect(() => {
    getCarteira();
  }, [getCarteira]);

  return (
    <>
      {(componenteRenderizado) ? <DownloadCarteiras carteira={carteira} setComponenteRenderizado={setComponenteRenderizado} />
        :
        (largura >= 920) ?
          <DivGlobal>
            <Header>
              <div className="container_img">
                <img src={`${apiUrl}/images/logo_unisc.png`} alt="logo" />
              </div>
              <div className="menu_pc" >
                <a href='/'>VOLTAR</a>
                <a href={MensagemWhatsAppAjuda}>CONTATO</a>
                {
                  carteira.status === 'Cancelada' || carteira.status === 'Expirada' ?
                    <button
                      className="btn_renovar"
                      onClick={handleClickRenovar}
                      disabled={renovacaoClicada}
                    > RENOVAR </button> :
                    carteira.status === 'Validada' ?
                      <button className="download" onClick={(e) => handleClickDownload(e)}>DOWNLOAD</button> :
                      carteira.status === 'Recepcionada' && null
                }
              </div>
            </Header>
            <Section>
              <div className="tittle">
                <h1> DOCUMENTO NACIONAL DO ESTUDANTE (DNE) </h1>
              </div>
              <div className="container_cards">
                <div className="card1">
                  <div className="student_img">
                    <img src={carteira.foto} alt="foto" />
                  </div>
                  <div className="student_data">
                    <div className="student_name">
                      <span className="title">Nome:</span>
                      <span>{carteira.name}</span>
                    </div>
                    <div className="student_class">
                      <span className="title">Instituição de ensino:</span>
                      <span>{carteira.instituicaoEnsino}</span>
                    </div>
                    <div className="student_course">
                      <span className="title">Curso:</span>
                      <span>{carteira.curso}</span>
                    </div>
                    <p className="student_documents">Documentos:</p>
                    <div className="student_cpf">
                      <span className="title">CPF: </span>
                      <span>{carteira.cpf}</span>
                    </div>
                    <div className="student_rg">
                      <span className="title">RG: </span>
                      <span>{carteira.rg}</span>
                    </div>
                    <div className="student_birth">
                      <span className="title">Data Nasc: </span>
                      <span>{carteira.dataNascimento}</span>
                    </div>
                  </div>
                </div>
                <div className="card2">
                  {carteira.status !== 'Validada' ?
                    null :
                    <>
                      <div className='infos'>
                        <div className="student_cod">
                          <span>Código de uso</span>
                          <span>{carteira.codigoUso}</span>
                        </div>
                        <div className="expiration">
                          <span>Validade</span>
                          <span> {moment(carteira.dataValidade).locale('pt-br').format('DD/MM/YYYY')}</span>
                        </div>
                      </div>
                    </>
                  }

                  {
                    carteira.status === 'Validada' ?
                      <button className="status_valida">Carteira Válida!</button>
                      : carteira.status === 'Cancelada' || carteira.status === 'Expirada' ?
                        <button className="status_expirada">Expirada!</button>
                        : carteira.status === 'Recepcionada' &&
                        <button className="status_recepcionada">Recepcionada!</button>
                  }
                </div>
              </div>
            </Section>
            <Footer>
              <div className="social_name">
                <h3> UNISC ESTUDANTIL </h3>
              </div>
              <div className="complete_name">
                <span>
                  UNIAO ESTUDANTIL NACIONAL DE INCENTIVO
                  SOCIAL E CULTURAL
                </span>
                <span>CNPJ: 31.370.444/0001-42</span>
              </div>
              <div className="contact">
                <span>uniscestudantil@gmail.com</span>
              </div>
            </Footer>
          </DivGlobal>
          :
          <VisualizarCarteirasMobile />
      }
    </>
  )
}

export default NovaPaginaVisualizarCarteiras;