import React from "react";
import { DialogActions, DialogContent, Dialog, DialogTitle, Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import { cpfMask } from "../../services/funcoesAuxiliares";

const CadastrarGestor = ({ show, cadastroGestores, props, handleClose}) => {

  const {
    nome,
    setNome,
    cpf,
    setCpf,
    role,
    setRole,
    telefone,
    setTelefone,
    estado,
    setEstado,
    pass,
    setPass,
    email,
    setEmail
  } = props

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle> Cadastrar Gestores </DialogTitle>
      <DialogContent>
        <Box 
        component="form"
        sx={{'& .MuiTextField-root': { margin: 5, width: '25ch' }, display: "flex" , flexWrap: 'wrap'}}
        >
          <TextField id="Name-Basic" value={nome} onChange={({ target }) => setNome(target.value)} label="Nome" variant="outlined" />
          <TextField id="Pass-basic" type="password" value={pass} onChange={({ target }) => setPass(target.value)} label="Password" variant="outlined" autoComplete="true" />
          <TextField id="Email-basic" type="email" value={email} onChange={({ target }) => setEmail(target.value)} label="Email" variant="outlined" />
          <TextField id="Cpf-basic" value={cpf} onChange={({ target }) => setCpf(cpfMask(target.value))} label="Cpf" variant="outlined" />
          <TextField id="Telefone-basic" value={telefone} onChange={({ target }) => setTelefone(target.value)} label="Telefone" variant="outlined" />
          <TextField id="Cargo-basic" value={role} onChange={({ target }) => setRole(target.value)} label="Cargo" variant="outlined" />
          <TextField id="Estado-basic" value={estado} onChange={({ target }) => setEstado(target.value)} label="Estado" variant="outlined" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>NÃO</Button>
        <Button onClick={() => cadastroGestores(nome, pass, email, cpf, telefone, role, estado)} autoFocus>
          SIM
        </Button>
      </DialogActions>
    </Dialog>)

}

export default CadastrarGestor;