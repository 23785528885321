import styled from "styled-components";
// import Menu from "@styled-icons/heroicons-solid/Menu";
import { Menu } from "@styled-icons/boxicons-regular/Menu";

export const DivGlobal = styled.div`
position: relative;
max-width: 920px;
margin: 0;
padding: 0;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo semi-transparente */
    display: ${props => (props.isOpen ? 'block' : 'none')};
    z-index: 998; /* Certifique-se de que o z-index do overlay é menor do que o z-index do botão */
    /* Outros estilos */
}
`;

export const MenuToggle = styled(Menu)`
    color: #ffffff;
    cursor: pointer;
    width: 10vw;
    z-index: 999;

    @media screen and (max-width: 800px) and (max-height: 600px) {
        width: 8vw;
    }
`;

export const MenuList = styled.ul`
    z-index: 999;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 8vh;
    width: 100%;
    background-color: #FBF5EF;
    transition: max-height 0.5s ease, opacity 1s ease; /* Adiciona uma transição suave para a propriedade max-height e opacity */

    max-height: ${props => (props.isOpen ? '200px' : '0')}; /* Define a altura máxima para 200px quando aberto e 0 quando fechado */
    opacity: ${props => (props.isOpen ? '1' : '0')}; /* Define a opacidade para 1 quando aberto e 0 quando fechado */
    overflow: hidden; /* Esconde o conteúdo além da altura máxima */

    @media only screen and (max-width: 800px) and (max-height: 600px) {
        top: 50px;
    }

    li {
        display: inline-block;
        margin-bottom: 10px;
    }

    li button {
        margin: 20px;
        height: 6vh;
        width: 30vw;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: #ffffff;

        &:hover {
            color: #555;
        } 
    }

    li:first-child .btn_renovar{
        background-color: #d40505;
        border: none;
    }

    li:first-child .btn_download{
        background-color: #FAB035;
        border: none;
    }

    li:nth-child(2) button {
        background-color: #3B71DA;
        border: none;
    }
`;

export const Header = styled.header`
display: flex;
justify-content: space-around;
align-items: center;
height: 8vh;
width: 100%;
background-color: #3B71DA;

    .container_img {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 25vw;
            img {
                max-height: 80%;

                @media screen and (max-width: 300px) {
                    max-height: 10vw;    
                }
            }
    }
`;


export const Section = styled.section`
display: flex;
flex-direction: column;
background-color: #FBF5EF;

    .tittle {
        text-align: center;
        font-family: 'Neo Sans Pro', sans-serif;
        font-size: 4vw;
    }

    .container_cards {

        .card1 {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .student_img {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 50%;

                img {
                    width: 30vw;
                }
            }

            .infos {
                display: flex;
                flex-direction: column;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                font-size: 4vw;
                text-align: center;

                .student_cod {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    span {
                        margin-bottom: 5px;
                    }
                }

                .expiration {
                    display: flex;
                    flex-direction: column;
                    span {
                        margin-bottom: 5px;
                    }
                }

            }
        }


        .card2 {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .student_data {
                margin-left: 30px;
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;
                p {
                        font-family: 'Neo Sans Pro', sans-serif;
                        font-weight: 500;
                        margin-bottom: 2px;
                    }


                div:nth-child(-n+3) {
                margin-bottom: 2px;
                display: flex;
                flex-direction: column;

                    .title {
                        font-family: 'Neo Sans Pro', sans-serif;
                        margin-bottom: 0;
                        font-size: 4vw;
                    }

                    span {
                        margin: 2px 0 2px 0;
                        font-family: 'Neo Sans Pro', sans-serif;
                        font-weight: 500;
                        font-size: 5vw;        
                    }
                }
            
                > div:nth-last-child(-n+3) { 
                    margin-bottom: 2px;

                    .title {
                        font-family: 'Neo Sans Pro', sans-serif;
                        margin-bottom: 0;
                        font-size: 4vw;                    
                    }

                    span {
                        margin: 2px 0 2px 0;
                        font-family: 'Neo Sans Pro', sans-serif;
                        font-weight: 500;
                        font-size: 5vw;     
                    }
                }

            }

            .status_valida {
                align-self: center;
                border: none;
                background-color: #57e5a077;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                height: 6vh;
                width: 50vw;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                color: #138A51;
                font-size: 5vw;

                @media screen and (max-width: 800px) and (max-height: 600px) {
                    height: 10vh;
                    width: 50vw;
                }
            }

            .status_expirada {
                align-self: center;
                border: none;
                background-color: #ba00006d;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                height: 6vh;
                width: 50vw;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                color: #ba0000;
                font-size: 5vw;

                @media screen and (max-width: 800px) and (max-height: 600px) {
                    height: 10vh;
                    width: 50vw;
                }
            }

            .status_recepcionada {
                align-self: center;
                border: none;
                background-color: #3b70da90;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                height: 6vh;
                width: 50vw;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                color: #3b70da;
                font-size: 5vw;

                @media screen and (max-width: 800px) and (max-height: 600px) {
                    height: 10vh;
                    width: 50vw;
                }
            }
        }
    }
`;

export const Footer = styled.footer`
background-color: #DDE0E4;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

.social_name {
    margin-top: 15px;
    h3 {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        margin-bottom: 5px;
        font-size: 1em;
    }
}

.complete_name {
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;

    span {
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 0.6em;
        margin-bottom: 5px;
    }

}

.contact {
    margin-bottom: 20px;
    span {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 0.5em;
    }
}
`;
