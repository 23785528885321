import React from "react";
import { DialogActions, DialogContent, Dialog, DialogTitle,DialogContentText} from '@mui/material';
import Button from '@mui/material/Button';;


const DeletarCarteiras = ({show, dataFinal,dataInicial, status, gestorId, deletarCarteiras,handleClose}) => {

return (
<Dialog
  open={show}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  >
  <DialogTitle> Deletar Carteiras </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Deletar Permanentemente?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>NÃO</Button>
    <Button onClick={() => deletarCarteiras(dataInicial, dataFinal, status, gestorId)} autoFocus>
      SIM
    </Button>
  </DialogActions>
  </Dialog>)

}

export default DeletarCarteiras;