import styled from "styled-components";
import { MultiSelect } from "react-multi-select-component";
import { Filter } from "@styled-icons/fa-solid";
import { Eraser } from "@styled-icons/fa-solid";

export const FiltroIcon = styled(Filter)`
max-width: 15px;
`;

export const Borracha = styled(Eraser)`
max-width: 20px;
`;

export const Section = styled.section`
position: relative;
padding-bottom: 20px;
border-bottom: 1px solid;
display: flex;
flex-direction: column;

.container { 
  display: flex;
  justify-content: space-between;
}

.ButtonsFiltro {
padding: 0 5% 0 5%;
border-left: 1px solid;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

}

.botaoFiltrar {
  border-radius: 5px;
  padding: 5px;
  width: 80px;
  display: flex;
  margin: 10px;
  color: white;
  background-color: orange;
  align-items: center;
  justify-content: space-around;
  border: none;
  

  }

  .botaoLimparFiltro {
  border-radius: 5px;
  padding: 5px;
  width: 120px;
  display: flex;
  margin: 10px;
  color: white;
  background-color: #77c244;
  align-items: center;
  justify-content: space-around;
  border: none;
  white-space: nowrap;
}

form {
  display: flex;
  align-items: center;
  max-width: 1400px;
  flex-wrap: wrap;
}

input {
  margin: 5px;
}

label {
  margin: 5px;
  display: flex;
  align-items: center;
}
`;

export const SelectStatus = styled(MultiSelect)`
margin-left: 5px;
width: 150px;
`;

export const SelectGestores = styled(MultiSelect)`
margin-left: 5px;
width: 150px;
`;

export const ContainerRelevanciaNome = styled.div`
max-height: 200px;
width: 300px;
padding-left: 5px;
position: absolute;
z-index: 1;
box-shadow: -1px 1px 10px black;
top: 105px;
left: 360px;
background-color: #fbfbfb;
overflow: hidden;
overflow-y: auto;

.itens {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
}
`;

export const ContainerRelevanciaCodUso = styled.div`
max-height: 200px;
width: 300px;
top: 110px;
left: 0;
position: absolute;
padding-left: 5px;
box-shadow: -1px 1px 10px black;
z-index: 1;
background-color: #fbfbfb;
overflow: hidden;
overflow-y: auto;

.itens {
  display: flex;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
  gap: 12px;
}
`;
