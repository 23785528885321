export const funcaoOrganizar = (array) => {

  const groupedByCPF = array.reduce((acc, currentValue) => {
    if (!acc[currentValue.cpf]) {
      acc[currentValue.cpf] = [currentValue];
    } else {
      acc[currentValue.cpf].push(currentValue);
    }
    return acc;
  }, {});

  const merged = Object.values(groupedByCPF).map((group) => {
    const sortedGroup = group.sort((a, b) => new Date(b.dataEmissao) - new Date(a.dataEmissao));
    const [current, ...renovacoes] = sortedGroup;
    return {
      ...current,
      renovacoes
    };
  });

  return merged;
}

export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const organizarPorEstado = (arrayA, arrayB) => {

  const estadosExistentes = arrayB.map((e) => e.estado);
  const guardandoEstados = arrayA.filter((a) => estadosExistentes.includes(a));
  const y = guardandoEstados.map((e) => (
    {
      [e]: arrayB.filter((x) => (e === x.estado)),
    }
  ))
  return y;
};


export const arrayEstados = [
'Acre',
'Alagoas',
'Amapá',
'Amazonas',
'Bahia',
'Ceará',
'Distrito Federal',
'Espirito Santo',
'Goiás',
'Maranhão',
'Mato Grosso do Sul',
'Mato Grosso',
'Minas Gerais',
'Pará',
'Paraíba',
'Paraná',
'Pernambuco',
'Piauí',
'Rio de Janeiro',
'Rio Grande do Norte',
'Rio Grande do Sul',
'Rondônia',
'Roraima',
'Santa Catarina',
'São Paulo',
'Sergipe',
'Tocantins',
]
