import React, { useState } from "react";
import { apiUrl} from "../services/requests";

const EnviarImagem = ({ img, setImg }) => {
  const [imagemInicial] = useState(`${apiUrl}/images/0.png`);

  return (
    <>
        <input className="inputImage" type="file" name="imagem" onChange={(e) => setImg(e.target.files[0])} />
        {img ? <img className="image" src={URL.createObjectURL(img)} alt="imagem" /> : <img className="image" src={imagemInicial} alt="imagem" />}
    </>
  )
}

export default EnviarImagem;